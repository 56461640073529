<template>
  <section>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="filterTasrNewsCategory">
          <div class="row">
            <div class="col-lg-2 col-md-6">
              <app-select
                v-model="filter.site"
                :options="sites"
                :label="$t('tasrNewsCategory.filter.site')"
                id="filter_site"
                @change="loadRubricsBySite"
              >
              </app-select>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-select
                v-model="filter.rubric"
                :options="rubrics"
                :label="$t('tasrNewsCategory.filter.rubric')"
                id="filter_rubric"
              >
              </app-select>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-select
                v-model="filter.service"
                :options="valueObjectServiceType"
                :label="$t('tasrNewsCategory.filter.service')"
                id="filter_service"
                noEmptyValue
              >
              </app-select>
            </div>
            <div class="col-lg-1 col-md-6">
              <app-input v-model="filter.code" id="filter_code" :label="$t('tasrNewsCategory.filter.code')"></app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input v-model="filter.title" id="filter_title"
                         :label="$t('tasrNewsCategory.filter.title')"></app-input>
            </div>
            <div class="col-lg-3">
              <button class="btn btn-success pull-right m-t-30">
                {{ $t('tasrNewsCategory.filter.search') }}
              </button>
              <button
                type="button"
                class="btn btn-secondary m-t-30"
                data-test="reset_filter"
                @click="resetFilter"
              >
                {{ $t('buttons.reset_filter') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import Input from '../form/inputs/Input'
import TasrNewsCategoryFilter from '../../model/TasrNewsCategoryFilter'
import Select from '../form/select/Select'
import ValueObjectService from '../../services/ValueObjectService'

export default {
  name: 'TasrNewsCategoryFilter',
  data () {
    return {
      isLoading: false,
      filter: this._.cloneDeep(TasrNewsCategoryFilter),
      rubrics: []
    }
  },
  computed: {
    valueObjectServiceType () {
      return ValueObjectService.getAsArray(this.$store.getters['tasrNews/valueObjectServiceType'])
    },
    sites () {
      return this.$store.getters['site/enabledSites']()
    }
  },
  components: {
    appInput: Input,
    appSelect: Select
  },
  methods: {
    filterTasrNewsCategory () {
      this.$store.commit('tasrNewsCategory/setPage', 1)
      this.$store.commit('tasrNewsCategory/setFilter', this.filter)
      this.$store.dispatch('tasrNewsCategory/fetch')
    },
    resetFilter () {
      this.filter = this._.cloneDeep(TasrNewsCategoryFilter)
      this.$store.commit('tasrNewsCategory/setFilter', this.filter)
      this.$store.dispatch('tasrNewsCategory/fetch')
    },
    async loadRubricsBySite () {
      await this.$store.dispatch('rubric/fetchRubricsBySiteId', this.filter.site)
      this.rubrics = this.$store.state.rubric.all.filter(item => item.site === this.filter.site)
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['tasrNewsCategory/filter']
  }
}
</script>
