<template>
  <app-module-view>
    <template slot="buttons">
      <app-button-create
        route-name="tasrNewsCategory_new"
      >
      </app-button-create>
    </template>
    <template slot="body">
      <app-tasrNewsCategory-filter></app-tasrNewsCategory-filter>
      <div class="card">
        <div class="card-body">
          <app-preloader v-if="callingAPI"></app-preloader>
          <app-data-table
            :data="tasrNewsCategory"
            :totalCount="totalCount"
            :page="page"
            :config="dataTableConfig"
            @page-change="setPageAndGetRecords"
          >
          </app-data-table>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../ModuleView'
import { mapState } from 'vuex'
import Preloader from '../preloader/Preloader'
import TasrNewsCategoryFilter from './TasrNewsCategoryFilter'
import DataTable from '../shared/DataTable'
import ValueObjectServiceTypeService from '../../services/tasr/ValueObjectServiceTypeService'
import SiteService from '../../services/site/SiteService'
import RubricService from '../../services/rubric/RubricService'
import ButtonCreate from '../shared/ButtonCreate'

export default {
  name: 'TasrNewsCategoryList',
  data () {
    return {
      dataLoaded: false,
      dataTableConfig: {
        fields: {
          id: this.$t('tasrNewsCategory.list.id'),
          site: this.$t('tasrNewsCategory.list.site'),
          rubric: this.$t('tasrNewsCategory.list.rubric'),
          service: this.$t('tasrNewsCategory.list.service'),
          code: this.$t('tasrNewsCategory.list.code'),
          title: this.$t('tasrNewsCategory.list.title')
        },
        actions: {
          edit: 'tasrNewsCategory_edit'
        },
        render: {
          service: function (data) {
            return (data) ? ValueObjectServiceTypeService.getPrettyName(data) : ''
          },
          site: function (data) {
            if (data) {
              return SiteService.getSiteTitle(data)
            }
          },
          rubric: function (data) {
            if (data) {
              return RubricService.getRubricTitle(data)
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    tasrNewsCategory () {
      return this.$store.getters['tasrNewsCategory/list']
    },
    totalCount () {
      return this.$store.getters['tasrNewsCategory/totalCount']
    },
    page () {
      return this.$store.getters['tasrNewsCategory/page']
    }
  },
  components: {
    appModuleView: ModuleView,
    appPreloader: Preloader,
    appTasrNewsCategoryFilter: TasrNewsCategoryFilter,
    appDataTable: DataTable,
    appButtonCreate: ButtonCreate
  },
  methods: {
    getTasrNewsCategory () {
      this.$store.dispatch('tasrNewsCategory/fetch')
        .then(() => {
          this.dataLoaded = true
        })
    },
    setPageAndGetRecords (page) {
      this.$store.commit('tasrNewsCategory/setPage', page)
      this.getTasrNewsCategory()
    }
  },
  mounted () {
    this.getTasrNewsCategory()
  },
  beforeMount () {
    this.$store.dispatch('tasrNews/fetchValueObjectServiceType')
  }
}
</script>
